// extracted by mini-css-extract-plugin
export var banner = "index-module--banner--76afb";
export var bannerImage = "index-module--banner-image--0f2ac";
export var bannerSeparator = "index-module--banner-separator--02d05";
export var bannerSubtitle = "index-module--banner-subtitle--5a1ac";
export var bannerTitle = "index-module--banner-title--1015d";
export var contactDetails = "index-module--contact-details--89895";
export var homePageImage = "index-module--home-page-image--bc6ff";
export var homePageSection = "index-module--home-page-section--a71f0";
export var index = "index-module--index--52adc";
export var stickyContainer = "index-module--sticky-container--ee2c3";